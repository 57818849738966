import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IContractFolderItem } from "../../@types/contract";
import { axiosBaseQuery } from "redux/axiosBaseQuery";
import { ICreateFolder } from "../../@types/inbox";
import { IContentListResponse } from "../../@types/contractResponse";

export type IFetchContentListPayload = {
    folderId?: string,
    rowsPerPage?: number,
    page?: number,
    currentPageNumber?: number
    parties?: string
    onlyContracts?: boolean
    search?: boolean,
    sortField?: string,
    sortOrder?: string,
    title?: string,
}

export type IUpdateContentListPayload = {
    uuid: string,
    payload: ICreateFolder
}

export const contentApi = createApi({
    reducerPath: "API/CONTENT",
    baseQuery: axiosBaseQuery(),
    tagTypes: ['contents'],
    endpoints(builder) {
        return {
            fetchContentList: builder.query<IContentListResponse, IFetchContentListPayload>({
                query({ folderId, rowsPerPage = 20, page = 1, currentPageNumber, parties, onlyContracts, search, sortField, sortOrder, title }) {

                    const params: any = {
                        onlyContracts: onlyContracts ? onlyContracts : false,// default to false
                        pageSize: rowsPerPage,
                        pageNumber: currentPageNumber ? currentPageNumber : page,
                        search: search ? search : false,
                        sortField: sortField ? sortField : '',
                        sortOrder: sortOrder ? sortOrder : '',
                        title: title ? title : ''
                    }

                    if (folderId) {
                        params.folderUuid = folderId
                    }

                    if (parties) {
                        params.parties = parties
                    }

                    let endpointURL = `/cs-api/contents`
                    return {
                        url: endpointURL,
                        params
                    };
                },
                providesTags: (result, error, { folderId }) =>
                    result ? [{ type: 'contents', id: folderId }] : [],
            }),
            updateContent: builder.mutation<string, IUpdateContentListPayload>({
                query: ({ uuid, payload }) => ({
                    url: `/cs-api/folders/${uuid}`,
                    data: payload,
                    method: 'PUT',
                }),
                async onQueryStarted({ uuid, payload }, { dispatch, getState, queryFulfilled }) {
                    const queriesData = getState()['API/CONTENT'].queries;
                    let args: IFetchContentListPayload = {};

                    for (const key in queriesData) {
                        const data: any = queriesData[key]?.data;
                        const isMatch = data?.content.find((e: IContractFolderItem) => e.uuid === uuid);
                        if (isMatch) {
                            args = queriesData[key]?.originalArgs as any;
                        }
                    }

                    const patchResult = dispatch(
                        contentApi.util.updateQueryData('fetchContentList', args, (draft: any) => {

                            draft.content.map((item: IContractFolderItem) => {
                                if (item.uuid === uuid) {
                                    item.name = payload.name;
                                }
                                return item;
                            });
                        })
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),
        };
    }
})

export const { useFetchContentListQuery, useUpdateContentMutation } = contentApi