import { Box } from '@mui/material';
import { IContractFolderItem } from '../../../../../@types/contract';
import Iconify from 'components/Iconify';
import { EditContractContext } from 'contexts/EditContractContext';
import React, { useContext, useState } from 'react'
import { SharedContext } from 'contexts/SharedContext';
import ManageMoveFolder from 'pages/contracts/move-folder/manage-move-folder';
import useLocales from 'hooks/useLocales';
import { useDispatch, useSelector } from 'redux/store';
import { setPermissions } from 'redux/slices/permissions/permissionsSlice';
import { contentPermissionAPI, IGetContentPermissionParams } from 'redux/api/contentPermission';
import { createGroupPermission, createUserPermission, sortPermissions } from 'utils';
import { LoadingButton } from '@mui/lab';
import FolderIcon from '@mui/icons-material/Folder';

type Props = {}

const LocationDetails = (props: Props) => {
    const { permissions } = useSelector((store) => store.permissionsStore);
    const dispatch = useDispatch()

    const { contract, isLoadingContract } = useContext(EditContractContext)
    const { inbox } = useContext(SharedContext)
    const { translate } = useLocales()
    const [selectedFolder, setSelectedFolder] = useState<IContractFolderItem | null | undefined>();
    const [openMoveFolderDialog, setOpenMoveFolderDialog] = React.useState(false);
    const [folder, setFolder] = useState<IContractFolderItem>()



    const handleCloseMoveDialog = () => {
        setOpenMoveFolderDialog(false);
        // setError('');
    };

    const handleSelectedFolderOutside = async (folder: IContractFolderItem) => {
        const permissionsList = permissions.filter((e) => e.saveable);
        const inboxId = +inbox?.id!
        let objParams: IGetContentPermissionParams = {
            id: inboxId,
            type: "inbox"
        };
        if (folder) {
            objParams = {
                id: folder.id,
                type: "folder"
            }
            setSelectedFolder(folder);
        } else {
            setSelectedFolder(null);
        }

        dispatch(setPermissions([]));

        const { data: { userPermissions, userGroupPermissions } } = await dispatch(contentPermissionAPI.endpoints.getContentPermissions.initiate(objParams))

        const usersPermission = userPermissions
            ? userPermissions.map((user: any) => createUserPermission(user, true))
            : [];

        const groupsPermission = userGroupPermissions
            ? userGroupPermissions.map((group: any) => createGroupPermission(group, true))
            : [];

        const extraUsers = usersPermission.filter(
            (e: any) => !permissionsList.some((p: any) => p.type === 'user' && p.id === e.id)
        );
        const extraGroups = groupsPermission.filter(
            (e: any) => !permissionsList.some((p: any) => p.type === 'group' && p.id === e.id)
        );

        dispatch(setPermissions(sortPermissions([...permissionsList, ...extraUsers, ...extraGroups])));
    };

    let folderPath: JSX.Element[] | null | undefined = null;
    if (selectedFolder === null) {
        folderPath = null;
    } else if (selectedFolder?.folderPath) {
        folderPath = selectedFolder?.folderPath?.map((e) => (
            <React.Fragment key={e.id}>
                <Iconify icon={'ic:round-navigate-next'} />
                <Box component={'span'}>{e.name}</Box>
            </React.Fragment>
        ));
    } else {
        folderPath = contract?.folder?.folderPath?.map((e) => (
            <React.Fragment key={e.id}>
                <Iconify icon={'ic:round-navigate-next'} />
                <Box key={e.id} component={'span'}>
                    {e.name}
                </Box>
            </React.Fragment>
        ));
    }

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", fontWeight: "600" }}>

                <FolderIcon
                    sx={{ width: 26, height: 26, mr: 0.8 }}
                    width={26}
                    height={26}
                    color="warning"
                />
                <Box component={'span'}>{inbox?.name}</Box>
                {folderPath}
            </Box>
            <Box>
                <LoadingButton
                    loading={isLoadingContract}
                    disabled={isLoadingContract}
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: '2rem', marginTop: '1rem' }}
                    size="small"
                    onClick={() => setOpenMoveFolderDialog(true)}
                >
                    {translate('common.selectFolder', 'Select folder')}
                </LoadingButton>

                {openMoveFolderDialog ? (
                    <ManageMoveFolder
                        item={contract as any}
                        handleCloseMoveDialog={() => handleCloseMoveDialog()}
                        open={openMoveFolderDialog}
                        handleSelectedFolderOutside={handleSelectedFolderOutside}
                        titleTip={translate('moveFolder.save', 'Save')}
                    />
                ) : null}
            </Box>
        </>

    )
}

export default LocationDetails